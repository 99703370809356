import React from 'react';
import { FaBriefcase, FaLaptopCode, FaUserGraduate } from 'react-icons/fa';
import '../css/Experience.css';

const Experience = () => {
  return (
    <section id="experience" className="experience-section">
      <h1>Work Experience</h1>

      <div className="experience-grid">
        {/* CARD #1 */}
        <div className="job-card">
          <div className="card-header">
            <span className="job-icon">
              <FaBriefcase />
            </span>
            <h2>Full Stack Developer | Sword Garage Web & Mobile App</h2>
            <span className="job-dates">October 2024 – Present | Toronto, Canada</span>
          </div>
          <div className="card-body">
            <ul>
              <li>Designed and deployed a multilingual landing page and mobile mechanic booking platform using React, i18n.js, and 13 reusable components across 6 languages.</li>
              <li>Optimized SEO using React Helmet, sitemaps, and Google Search Console for improved visibility and performance.</li>
              <li>Developed Flask-based backend for secure user authentication, appointment scheduling, and availability management using JWT and MongoDB.</li>
              <li>Integrated advanced APIs for appointment booking, user roles, and car data management.</li>
            </ul>
          </div>
        </div>

        {/* CARD #2 */}
        <div className="job-card">
          <div className="card-header">
            <span className="job-icon">
              <FaLaptopCode />
            </span>
            <h2>Backend Developer | TrioTech Web</h2>
            <span className="job-dates">August 2024 – October 2024 | Calgary, Canada</span>
          </div>
          <div className="card-body">
            <ul>
              <li>Built a full-stack messaging system with a React frontend and Flask backend, enabling secure authentication (JWT), data validation, and MongoDB integration.</li>
              <li>Developed a secure backend for an invoice management application using Express.js and MongoDB, with role-based access control and scalable RESTful APIs.</li>
              <li>Led the development of an eCommerce platform with product and order management features, secure user authentication, and efficient cart handling.</li>
            </ul>
          </div>
        </div>

        {/* CARD #3 */}
        <div className="job-card">
          <div className="card-header">
            <span className="job-icon">
              <FaLaptopCode />
            </span>
            <h2>Full Stack Developer | Koyuncu Elektronik</h2>
            <span className="job-dates">December 2023 – August 2024 | Istanbul, Turkey</span>
          </div>
          <div className="card-body">
            <ul>
              <li>Revamped B2B e-commerce portals using React.js and Node.js, improving dealer order processing by 30%.</li>
              <li>Integrated logistics APIs (Samsung, TP-Link) to automate inventory tracking, reducing supply chain delays by 20%.</li>
              <li>Designed a JWT-secured authentication system for dealer portals, ensuring data security compliance with ISO 27001 standards.</li>
            </ul>
          </div>
        </div>

        {/* CARD #4 */}
        <div className="job-card">
          <div className="card-header">
            <span className="job-icon">
              <FaLaptopCode />
            </span>
            <h2>Junior Backend Developer | Ozkoyuncu Madencilik A.S</h2>
            <span className="job-dates">April 2023 – December 2023 | Kayseri, Turkey</span>
          </div>
          <div className="card-body">
            <ul>
              <li>Developed RESTful APIs for mining equipment sensor integration, enabling real-time monitoring of 2M+ tons/year iron ore production.</li>
              <li>Optimized MongoDB queries for geological data storage, improving report generation speed by 35%.</li>
              <li>Collaborated on ERP system modernization, enhancing inventory and shipment tracking.</li>
            </ul>
          </div>
        </div>

        {/* CARD #5 */}
        <div className="job-card">
          <div className="card-header">
            <span className="job-icon">
              <FaUserGraduate />
            </span>
            <h2>Backend Developer – Intern | Ozkoyuncu Madencilik A.S</h2>
            <span className="job-dates">May 2022 – August 2022 | Kayseri, Turkey</span>
          </div>
          <div className="card-body">
            <ul>
              <li>Automated Python scripts for quality control reporting, reducing manual workload by 50%.</li>
              <li>Supported Express.js/SQL backend development for enrichment plant dashboards, improving data visibility for operational teams.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
