import React, { useState } from 'react';
import '../css/Projects.css';

const projectData = [
  { name: 'Mechanic Pro App', description: 'React Native + Flask app managing brake service appointments, boosted workflow efficiency by 40%.', link: 'https://github.com/ozXoz/py-react-native', category: 'Full Stack (Flask + React)',    imageUrl: 'https://i.ibb.co/5kdQ1h8/Screenshot-2025-01-04-at-5-01-58-PM.png' },
  { name: 'TrioTeach-Backend-Ecom', description: 'Nodejs + express JS + React + Mongodb and other stuff', link: 'https://github.com/ozXoz/TrioTeach-Backend-Ecom', category: 'Full Stack (Node.js + React)',imageUrl:'https://i.ibb.co/0Z2RGM0/Screenshot-2025-01-04-at-5-07-12-PM.png' },
  { name: 'TrioTeach-Frontend-Ecom', description: 'Nodejs + express JS + React + Mongodb and other stuff', link: 'https://github.com/ozXoz/TrioTeach-Frontend-Ecom-', category: 'Full Stack (Node.js + React)',imageUrl:'https://i.ibb.co/6ry3rfL/Screenshot-2025-01-04-at-5-06-51-PM.png' },
  { name: 'TrioTeach-Backend-ERP', description: 'Nodejs + React MongoDb + Invoice app managing', link: 'https://github.com/ozXoz/TrioTeach-Backend-ERP', category: 'Full Stack (Node.js + React)', imageUrl:'https://i.ibb.co/47Pg1m5/Screenshot-2025-01-04-at-5-03-56-PM.png' },
  { name: 'TrioTeach-Frontend-ERP', description: 'Nodejs + React MongoDb + Invoice app managing', link: 'https://github.com/ozXoz/TrioTeach-Frontend-ERP', category: 'Full Stack (Node.js + React)',imageUrl:'https://i.ibb.co/qrt5rRd/Screenshot-2025-01-04-at-5-03-46-PM.png' },
  { 
    name: 'Sword Garage Mechanic', 
    description: 'Designed and deployed a multilingual landing page and mobile mechanic booking platform using React, i18n.js, and 13 reusable components across 6 languages. Optimized SEO using React Helmet, sitemaps, and Google Search Console, achieving strong responsiveness and performance.', 
    link: 'https://swordgarage.com', 
    category: 'React',
    imageUrl: 'https://i.ibb.co/KjmY6F86/Screenshot-2025-02-05-at-2-49-03-PM.png' 
  },

  { name: 'Nexyra Teach SaaS', description: 'React + Flask app managing Inventory Management, Sales & Order Management, Advanced Reporting.', link: 'https://www.nexyramanagement.ltd/', category: 'Full Stack (Flask + React)',imageUrl:'https://i.ibb.co/TYDmkxs/Screenshot-2025-01-04-at-5-09-32-PM.png' },
  { name: 'Personal Portfolio I J.T', description: 'React - Portfolio', link: 'https://j3y-c1.vercel.app/', category: 'React',imageUrl:'https://i.ibb.co/LdqZkb4/Screenshot-2025-01-04-at-5-11-30-PM.png' },
  { name: 'Personal Portfolio II A.S', description: 'React - Portfolio', link: 'https://client-saf1-hmet-portfolio.vercel.app/', category: 'React',imageUrl:'https://i.ibb.co/xLrWHQ9/Screenshot-2025-01-04-at-5-11-40-PM.png' },
  { name: 'Personal Portfolio III R.W', description: 'React - Portfolio', link: 'https://client-wemigwans.vercel.app/', category: 'React',imageUrl:'https://i.ibb.co/dfNLHJp/Screenshot-2025-01-04-at-5-11-46-PM.png' },
];

const Projects = () => {
  const [filter, setFilter] = useState('');

  // Filter the projects based on the selected category
  const filteredProjects = filter ? projectData.filter(project => project.category === filter) : projectData;

  return (
    <section id="projects" className="projects-section">
      <h1>Projects</h1>
      <select onChange={e => setFilter(e.target.value)} value={filter}>
        <option value="">All</option>
        <option value="React">React</option>
        <option value="Full Stack (Node.js + React)">Full Stack (Node.js + React)</option>
        <option value="Full Stack (Flask + React)">Full Stack (Flask + React)</option>
      </select>
      <div className="project-list">
        {filteredProjects.map((project, index) => (
          <div className="project-card" key={index}>
              <img src={project.imageUrl} alt={project.name} className="project-image" />
            <h2>{project.name}</h2>
            <p>{project.description}</p>
            <a href={project.link} target="_blank" rel="noopener noreferrer" className="btn-accent">View Project</a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;
